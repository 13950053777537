import { Component, OnInit, Input, ViewEncapsulation, HostBinding } from '@angular/core';

import { ContainerComponent } from '../container/container.component';
import { DraggableDirective } from '../../directives/ngx-draggable.directive';

/**
 * Component that allows nested ngxDroppable and ngxDraggables
 * Should only be use inside a ngx-dnd-container
 * Outside a ngx-dnd-container use ngxDroppable
 *
 * @export
 */
@Component({
  selector: 'ngx-dnd-item',
  template: `<ng-container [ngSwitch]="type">

  <ng-container *ngSwitchCase="'array'">
    <ngx-dnd-container
      [model]="model"
      [template]="container.template"
      [dropZone]="dropZone"
      [dropZones]="dropZones"
      [removeOnSpill]="removeOnSpill"
      [droppableItemClass]="droppableItemClass"
      [copy]="copy">
    </ngx-dnd-container>
  </ng-container>

  <ng-container *ngSwitchCase="'object'">
    <ng-template
      *ngIf="container.template"
      [ngTemplateOutlet]="container.template"
      [ngTemplateOutletContext]="data">
    </ng-template>
    <ng-container *ngIf="!container.template">
      <div
        class="ngx-dnd-content">
        {{model.label}}
      </div>
      <ngx-dnd-container
        *ngIf="model.children"
        [model]="model.children"
        [template]="container.template"
        [dropZone]="dropZone"
        [dropZones]="dropZones"
        [removeOnSpill]="removeOnSpill"
        [droppableItemClass]="droppableItemClass"
        [copy]="copy">
      </ngx-dnd-container>
    </ng-container>
  </ng-container>

  <ng-container *ngSwitchCase="'undefined'">
  </ng-container>

  <ng-container *ngSwitchDefault>
    <ng-template
      *ngIf="container.template"
      [ngTemplateOutlet]="container.template"
      [ngTemplateOutletContext]="data">
    </ng-template>
    <div
      *ngIf="!container.template"
      class="ngx-dnd-content">
      {{model}}
    </div>
  </ng-container>

</ng-container>







`,
  styles: [`.ngx-dnd-item{margin:10px;padding:10px;background-color:rgba(0,0,0,.2);transition:opacity .4s ease-in-out;border:1px solid #add8e6;display:block}.ngx-dnd-item.has-handle [ngxDragHandle],.ngx-dnd-item.has-handle [ngxdraghandle],.ngx-dnd-item:not(.has-handle):not(.move-disabled){cursor:move;cursor:grab;cursor:-webkit-grab}.ngx-dnd-item .ngx-dnd-content{-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none}.ngx-dnd-item:hover{border:1px solid #00f}.gu-mirror{position:fixed!important;margin:0!important;z-index:9999!important;opacity:.8}.gu-hide{display:none!important}.gu-unselectable{-webkit-user-select:none!important;-moz-user-select:none!important;-ms-user-select:none!important;user-select:none!important}.gu-transit{opacity:.2}`],
  encapsulation: ViewEncapsulation.None
})
export class ItemComponent implements OnInit {
  @Input() model: any;

  @Input()
  get dropZone() {
    return this._dropZone || this.container.dropZone;
  }
  set dropZone(val) {
    this._dropZone = val;
  }

  @Input()
  get dropZones() {
    return this._dropZones || this.container.dropZones;
  }
  set dropZones(val) {
    this._dropZones = val;
  }

  @Input()
  get droppableItemClass() {
    return this._droppableItemClass || this.container.droppableItemClass;
  }
  set droppableItemClass(val) {
    this._droppableItemClass = val;
  }

  @Input()
  get removeOnSpill() {
    return typeof this._removeOnSpill === 'boolean' ? this._removeOnSpill : this.container.removeOnSpill;
  }
  set removeOnSpill(val) {
    this._removeOnSpill = val;
  }

  @Input()
  get copy() {
    return typeof this._copy === 'boolean' ? this._copy : this.container.copy;
  }
  set copy(val) {
    this._copy = val;
  }

  _copy = false;
  _dropZone: any;
  _dropZones: any;
  _droppableItemClass: any;
  _removeOnSpill = false;
  data: any;

  get hasHandle(): boolean {
    return this.draggableDirective.hasHandle;
  }

  get moveDisabled(): boolean {
    return !this.draggableDirective.canMove();
  }

  @HostBinding('class')
  get classString() {
    const itemClass =
      typeof this.droppableItemClass === 'function' ? this.droppableItemClass(this.model) : this.droppableItemClass;

    const classes = ['ngx-dnd-item', itemClass || ''];
    if (this.moveDisabled) {
      classes.push('move-disabled');
    }
    if (this.hasHandle) {
      classes.push('has-handle');
    }
    return classes.join(' ');
  }

  get type() {
    if (Array.isArray(this.model)) {
      return 'array';
    }
    return typeof this.model;
  }

  constructor(public container: ContainerComponent, public draggableDirective: DraggableDirective) {}

  ngOnInit() {
    this.data = {
      model: this.model,
      type: this.type,
      dropZone: this.dropZone,
      template: this.container.template
    };
  }
}
