import {
  Component,
  OnInit,
  AfterViewInit,
  Input,
  Output,
  ViewEncapsulation,
  ContentChild,
  TemplateRef,
  ViewChild,
  EventEmitter
} from '@angular/core';

import { DroppableDirective } from '../../directives/ngx-droppable.directive';

let i = 0;
function getNextId() {
  return i++;
}

/**
 * Component that allows nested ngxDroppable and ngxDraggables
 *
 * @export
 */
@Component({
  selector: 'ngx-dnd-container',
  template: `<div
  ngxDroppable
  [dropZone]="dropZone"
  [model]="model"
  [copy]="copy"
  [ngClass]="{ 'gu-empty': !model?.length }"
  [removeOnSpill]="removeOnSpill"
  class='ngx-dnd-container'>
  <ng-container *ngIf="model">
    <ng-container *ngFor="let item of model">
      <ngx-dnd-item
        ngxDraggable
        [model]="item"
        [dropZone]="dropZone"
        [dropZones]="dropZones"
        [copy]="copy"
        [moves]="moves"
        [removeOnSpill]="removeOnSpill"
        [droppableItemClass]="droppableItemClass">
      </ngx-dnd-item>
    </ng-container>
  </ng-container>
  <ng-content *ngIf="!model"></ng-content>
</div>
`,
  styles: [`.ngx-dnd-container{background-color:rgba(255,255,255,.2);border:2px solid red;margin:10px;padding:10px}.ngx-dnd-container.gu-empty{border:2px dotted red}.ngx-dnd-container:nth-child(odd){background-color:rgba(0,0,0,.2)}.ngx-dnd-container .ex-moved{background-color:#e74c3c}.ngx-dnd-container .ex-over{background-color:rgba(255,255,255,.3)}.ngx-dnd-container .handle{padding:0 5px;margin-right:5px;background-color:rgba(0,0,0,.4);cursor:move}.no-select{-webkit-touch-callout:none;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none}`],
  encapsulation: ViewEncapsulation.None
})
export class ContainerComponent implements OnInit, AfterViewInit {
  @Input() model: any;
  @Input() copy = false;
  @Input() removeOnSpill = false;
  @Input() droppableItemClass: string | ((o: any) => any);

  @Input() dropZone = `@@DefaultDropZone-${getNextId()}@@`;

  @Input()
  get dropZones() {
    return this._dropZones || this._defaultZones;
  }
  set dropZones(val) {
    this._dropZones = val;
  }

  @Input() moves: (model: any, source: any, handle: any, sibling: any) => boolean;

  // @Input() classes: any = {};
  // @Input() dragulaOptions: any;

  @Input()
  @ContentChild(TemplateRef)
  template: TemplateRef<any>;

  @Input()
  @ViewChild(DroppableDirective)
  droppable: any;

  @Output() drop: EventEmitter<any> = new EventEmitter<any>();

  @Output() drag: EventEmitter<any> = new EventEmitter<any>();

  @Output() over: EventEmitter<any> = new EventEmitter<any>();

  @Output() out: EventEmitter<any> = new EventEmitter<any>();

  @Output() remove: EventEmitter<any> = new EventEmitter<any>();

  @Output() cancel: EventEmitter<any> = new EventEmitter<any>();

  _dropZones: string[];
  _defaultZones: string[];

  ngOnInit() {
    this._defaultZones = [this.dropZone];
  }

  ngAfterViewInit() {
    this.droppable.drag.subscribe((v: any) => this.drag.emit(v));
    this.droppable.drop.subscribe((v: any) => this.drop.emit(v));
    this.droppable.over.subscribe((v: any) => this.over.emit(v));
    this.droppable.out.subscribe((v: any) => this.out.emit(v));
    this.droppable.remove.subscribe((v: any) => this.remove.emit(v));
    this.droppable.cancel.subscribe((v: any) => this.cancel.emit(v));
  }
}
