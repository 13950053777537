import { Component, Input, Output, ViewChild, HostBinding, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { ShareButtons, ShareButtonDirective } from '@ngx-share/core';

@Component({
  selector: 'share-button',
  template: `<button class="sb-wrapper"
        [shareButton]="button"
        [url]="url"
        [image]="image"
        [title]="title"
        [description]="description"
        [tags]="tags"
        [autoSetMeta]="autoSetMeta"
        [getCount]="showCount"
        (opened)="opened.emit($event)"
        (closed)="closed.emit($event)"
        (count)="onCount($event)"
        [class.sb-show-icon]="showIcon"
        [class.sb-show-text]="showText"
        [class.sb-show-count]="showCount && shareCount"
        [style.fontSize.px]="(1 + size/20) * 14">

  <div class="sb-inner">

    <div class="sb-content">

      <!-- BUTTON ICON -->
      <div *ngIf="showIcon && ref.prop" class="sb-icon">
        <fa-icon [icon]="icon || ref.prop.icon"></fa-icon>
      </div>

      <!-- BUTTON TEXT -->
      <div *ngIf="showText && ref.prop" class="sb-text">
        {{ text || ref.prop.text }}
      </div>

    </div>

    <!-- BUTTON COUNT -->
    <div *ngIf="showCount && shareCount" class="sb-count">
      <span>{{ shareCount | shareCount }}</span>
    </div>

  </div>
</button>
`,
  changeDetection: ChangeDetectionStrategy.OnPush,
  preserveWhitespaces: false
})
export class ShareButtonComponent {

  /** Share URL */
  url: string;

  /** Share count value */
  shareCount: number;

  /** Button name */
  button: string;

  @Input('button')
  set createButton(button: string) {
    this.shareCount = 0;
    this.button = button;
  }

  /** Share URL */
  @Input('url')
  set setUrl(url: string) {
    this.shareCount = 0;
    this.url = url;
  }

  /** Share meta tags */
  @Input() title: string;
  @Input() description: string;
  @Input() image: string;
  @Input() tags: string;

  /** Set meta tags from document head, useful when SEO is supported */
  @Input() autoSetMeta: boolean;

  /** Show button icon */
  @Input() showIcon = true;

  /** Show button text */
  @Input() showText = false;

  /** Button share count */
  @Input() showCount = false;

  /** Button custom text */
  @Input() text: string;

  /** Button custom icon */
  @Input() icon: string;

  /** Button size */
  @Input() size: number = this.share.size;

  /** Button theme */
  @Input() theme: string = this.share.theme;

  /** Stream that emits when share count is fetched */
  @Output() count = new EventEmitter<number>();

  /** Stream that emits when share dialog is opened */
  @Output() opened = new EventEmitter<string>();

  /** Stream that emits when share dialog is closed */
  @Output() closed = new EventEmitter<string>();

  /** Set theme as button class */
  @HostBinding('class') get buttonClass() {
    return `sb-button sb-${this.theme}`;
  }

  /** Get button prop from ShareDirective */
  @ViewChild(ShareButtonDirective) ref: ShareButtonDirective;

  constructor(private share: ShareButtons) {
  }

  onCount(count) {
    this.shareCount = count;
    this.count.emit(count);
  }

}
